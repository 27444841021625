import React, {useEffect} from 'react';
import {AppStoreUrl, BreathLabAppStoreUrl, BreathLabPlayStoreUrl, PlayStoreUrl} from '../constants';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import {analytics} from '../App';

const DownloadBreathLabRedirect = () => {
    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
            // Redirect to Google Play Store
            window.location.href = BreathLabPlayStoreUrl;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            // Redirect to Apple App Store
            window.location.href = BreathLabAppStoreUrl;
        }
    }, []);

    return (
        <div className="sequence-page min-h-screen flex flex-col pt-40">
            <Navbar />
            <main className="flex-grow px-6 md:px-20 py-16 text-center">
                <h1 className="text-2xl font-bold text-gray-800 mb-4">
                    Redirecting you to download Breath Lab...
                </h1>
                <h2 className="text-lg font-medium text-gray-700 mb-6">
                    Make sure to open this link on your phone
                </h2>
                <p className="text-gray-600 mb-8">
                    If you are not redirected automatically, please click the links below:
                </p>
                <ul className="space-y-4">
                    <li>
                        <a
                            href={BreathLabAppStoreUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 underline hover:text-blue-800"
                        >
                            Download Breath Lab on the App Store
                        </a>
                    </li>
                    <li>
                        <a
                            href={BreathLabPlayStoreUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 underline hover:text-blue-800"
                        >
                            Get Breath Lab on Google Play
                        </a>
                    </li>
                </ul>
            </main>
            <Footer />
        </div>
    );

};

export default DownloadBreathLabRedirect;
