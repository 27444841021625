import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import {REACT_APP_RECAPTCHA_KEY_V3} from '../apiKeys';
import {logEvent} from 'firebase/analytics';
import {analytics} from '../App';

const ContactFormProvider = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_KEY_V3}>
            <ContactForm />
        </GoogleReCaptchaProvider>
    );
};

const ContactForm = () => {
    const navigate = useNavigate();
    const location = useLocation(); // To access the current URL and its query parameters
    const [urlParams, setUrlParams] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [charCount, setCharCount] = useState(0);
    const {executeRecaptcha} = useGoogleReCaptcha();

    logEvent(analytics, 'contact_form_visit');

    useEffect(() => {
        const query = new URLSearchParams(location.search); // Parse the query parameters
        const params = Object.fromEntries(query.entries()); // Convert to an object
        setUrlParams(params);
    }, [location.search]);

    const handleFormChange = (e) => {
        const {name, value} = e.target;

        if (name === 'message' && value.length > 500) return;

        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'message') {
            setCharCount(value.length);
        }
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Name is required.';

        if (!formData.email) {
            newErrors.email = 'Email is required.';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid.';
        }

        if (formData.subject !== "Request Promo Code" && !formData.message) {
            newErrors.message = 'Message is required.';
        }

        return newErrors;
    };


    const handleSubmit = async (token) => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        if (!token) {
            alert('reCAPTCHA verification is required.');
            return;
        }

        logEvent(analytics, 'contact_form_submit');

        try {
            const response = await fetch('https://api-x7pdhat42q-uc.a.run.app/recaptcha/siteverify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    captchaToken: token, // Send the token to your server
                    formData: {...formData, ...urlParams}, // Merge URL params with form data
                }),
            });

            const data = await response.json();

            if (data.success) {
                setFormData({name: '', email: '', subject: '', message: ''});
                setCharCount(0);
                navigate('/success');
            } else {
                alert('reCAPTCHA verification failed. Please try again.');
            }
        } catch (error) {
            console.error('Error during submission:', error);
            alert('Something went wrong. Please try again later.');
        }
    };

    const handleRecaptchaVerify = async (e) => {
        e.preventDefault();
        await validateForm();
        if (!executeRecaptcha) return;
        const token = await executeRecaptcha('contact_form_submit');
        handleSubmit(token);
    };

    return (
        <div className="sequence-page min-h-screen flex flex-col">
            <Navbar />
            <main className="flex-grow px-6 md:px-16 lg:px-32 pt-40 pb-16 text-center">
                <h2 className="text-3xl font-extrabold mb-6 text-gray-800">Contact Us</h2>
                <div className="max-w-2xl mx-auto">
                    <p className="text-gray-600 mb-10">
                        Have questions, feedback, or an interest in exploring collaboration opportunities? Need exclusive
                        promo codes for the App Store or Google Play to offer discounts to your students? Simply fill out
                        the form below, and we’ll get back to you as soon as possible!
                    </p>
                </div>
                <form onSubmit={handleRecaptchaVerify} className="bg-white shadow-md rounded-lg p-8 max-w-2xl mx-auto">
                    <div className="mb-6">
                        <label htmlFor="name" className="block text-left text-gray-700 font-semibold mb-2">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className={`w-full p-3 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            value={formData.name}
                            onChange={handleFormChange}
                            placeholder="Your Name"
                        />
                        {errors.name && <p className="text-red-500 text-sm mt-2 text-left">{errors.name}</p>}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="email" className="block text-left text-gray-700 font-semibold mb-2">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            value={formData.email}
                            onChange={handleFormChange}
                            placeholder="Your Email Address"
                        />
                        {errors.email && <p className="text-red-500 text-sm mt-2 text-left">{errors.email}</p>}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="subject" className="block text-left text-gray-700 font-semibold mb-2">Subject</label>
                        <select
                            id="subject"
                            name="subject"
                            className={`w-full p-3 border ${errors.subject ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            value={formData.subject}
                            onChange={handleFormChange}
                        >
                            <option value="" disabled>Select a subject</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Request Promo Code">Request Promo Code</option>
                            <option value="Feedback">Feedback</option>
                            <option value="Report Bug">Report Bug</option>
                            <option value="General Inquiry">General Inquiry</option>
                            <option value="Other">Other</option>
                        </select>
                        {errors.subject && <p className="text-red-500 text-sm mt-2 text-left">{errors.subject}</p>}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="message" className="block text-left text-gray-700 font-semibold mb-2">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            className={`w-full p-3 border ${errors.message ? 'border-red-500' : 'border-gray-300'} rounded-lg`}
                            rows="6"
                            value={formData.message}
                            onChange={handleFormChange}
                            placeholder="Write your message here..."
                        ></textarea>
                        {errors.message && <p className="text-red-500 text-sm mt-2 text-left">{errors.message}</p>}
                        <p className={`text-sm mt-2 text-left ${charCount > 500 ? 'text-red-500' : 'text-gray-500'}`}>
                            {charCount}/500
                        </p>
                    </div>

                    <button
                        type="submit"
                        className="w-full bg-black text-white font-semibold py-3 rounded-lg hover:bg-gray-800 transition duration-300"
                    >
                        Send Message
                    </button>
                </form>
            </main>
            <Footer />
        </div>
    );
};

export default ContactFormProvider;
