import React from 'react';
import {useLocation} from 'react-router-dom';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

function BreathLabTermsOfService() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const showNavigationBarsVal = queryParams.get('showNavigationBars');
    const showNavigationBars = (showNavigationBarsVal || 'true') === 'true';

    const currentPath = location.pathname;
    const isPrivacyAndTermsPath = currentPath === "/terms-and-privacy";
    console.log(`isPrivacyAndTermsPath: ${isPrivacyAndTermsPath}`);

    return (
        <div className="Terms">
            {
                (showNavigationBars && !isPrivacyAndTermsPath) ? <Navbar /> : null
            }
            <div
                style={{
                    margin: showNavigationBars ? 80 : 20,
                }}
            >
                <h1 className="text-3xl font-bold mb-8">Terms of Service</h1>
                <p className='mb-4'>Last updated April 08, 2025</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">AGREEMENT TO OUR LEGAL TERMS</h2>

                <p>
                    We are Sequence Studios Ltd (<strong>'Company'</strong>, <strong>'we'</strong>, <strong>'us'</strong>, or <strong>'our'</strong>), a company registered in the
                    United Kingdom at 71 - 75 Shelton Street, Covent Garden, London WC2H 9JQ.<br /><br />We operate the mobile application Breath Lab (the <strong>'App'</strong>),
                    the website <a href="https://www.sequence-studios.com" class="text-blue-500 hover:text-blue-700 underline">https://www.sequence-studios.com</a> (the <strong>'Website'</strong>), as well as any other related products and services that refer or link to these legal
                    as well as any other related products and services that refer or link to these legal
                    terms (the <strong>'Legal Terms'</strong>) (collectively, the <strong>'Services'</strong>).
                </p>
                <br />
                <p>
                    Our app is a mpbile application designed to help users improve their mental and physical well-being through guided breathwork exercises. The app provides access 
                    to a curated library of breathing sessions, personalized recommendations, and activity tracking to support users on their breathwork journey. The app is intended 
                    for informational and educational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment. By using the app, users acknowledge 
                    that they are responsible for their own health and safety during all activities.
                </p>
                <br />
                
                <br />
                <p>
                    By using the app, you acknowledge the benefits it provides and agree to engage responsibly and respectfully, adhering to the principles of
                    collaboration and professional conduct outlined in these Terms and Conditions.
                </p>
                <br />
                <p>
                    You can contact us by email at info@sequence-studios.com or by mail to 71 - 75 Shelton Street, Covent Garden, London WC2H 9JQ, United Kingdom.
                </p>
                <br />
                <p>
                    These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity (<strong>'you'</strong>), and Sequence
                    Studios Ltd, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by
                    all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
                    USE IMMEDIATELY.
                    <br />
                    <br />
                    Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference.
                    We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes
                    by updating the 'Last updated' date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility
                    to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted,
                    the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.
                </p>
                <br />
                <p>
                    The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.
                </p>
                <br />
                <p>
                    We recommend that you print a copy of these Legal Terms for your records.
                    <br />
                </p>


                <h2 className="text-2xl font-bold mt-8 mb-4">OUR SERVICES</h2>

                <p>The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where
                    such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
                    Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance
                    with local laws, if and to the extent local laws are applicable.
                    <br />
                    <br />
                    The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information
                    Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services
                    in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">INTELLECTUAL PROPERTY RIGHTS</h2>
                <h3 className="text-xl font-semibold mt-5 mb-2" id="Our intellectual property">Our intellectual property</h3>
                <p>
                    We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website
                    designs, audio, video, text, photographs, and graphics in the Services (collectively, the <strong>'Content'</strong>), as well as the trademarks, service
                    marks, and logos contained therein (the <strong>'Marks'</strong>).
                </p>
                <br />
                <p>
                    Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties
                    in the United States and around the world.
                </p>
                <br />
                <p>
                    The Content and Marks are provided in or through the Services <strong>'AS IS'</strong> for your personal, non-commercial use only.
                </p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Your use of our Services">Your use of our Services</h3>

                <p>
                    Subject to your compliance with these Legal Terms, including the '<a className="text-blue-500" href="#prohibited-activities">PROHIBITED ACTIVITIES</a>' section below, we grant you a non-exclusive, non-transferable,
                    revocable licence to:
                </p>
                <br />
                <ul className="list-disc ml-5">
                    <li>access the Services; and</li>
                    <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>
                </ul>
                <br />
                <p>solely for your personal, non-commercial use.</p>
                <br />

                <p>
                    Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated,
                    republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                    purpose whatsoever, without our express prior written permission
                </p>
                <br />
                <p>
                    If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your
                    request to: info@sequence-studios.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you
                    must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on
                    posting, reproducing, or displaying our Content.
                </p>
                <br />
                <p>
                    We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                </p>
                <br />
                <p>
                    Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate
                    immediately.
                </p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Copyright infringement">Copyright infringement</h3>
                <p>
                    We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own
                    or control, please immediately refer to the '<a className="text-blue-500" href="#copyright-infringements">COPYRIGHT INFRINGEMENTS</a>' section below.
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">USER REGISTRATION</h2>

                <p>
                    To access and use certain features of the Services, you may be required to register an account. Registration is currently available through third-party
                    login providers, including Apple, Google, and Facebook. By using one of these login options, you agree to provide accurate, current, and complete information
                    as requested by the selected provider.
                </p>
                <br />
                <p>
                    You are responsible for maintaining the confidentiality of your account and login credentials and for all activities that occur under your account. You agree
                    to immediately notify us of any unauthorized use of your account or any other breach of security.
                </p>
                <br />
                <p>
                    We reserve the right, at our sole discretion, to refuse or terminate your account, remove or reclaim usernames, or modify account details if we determine that
                    your account or username is inappropriate, obscene, misleading, or otherwise objectionable, or if it violates these Terms of Service or any applicable laws.
                </p>


                <h2 className="text-2xl font-bold mt-8 mb-4">USER REPRESENTATIONS</h2>

                <p>
                    By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will
                    maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply
                    with these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access the Services through automated or non-human means,
                    whether through a bot, script or otherwise; (6) you will not use the Services for any illegal or unauthorised purpose; and (7) your use of the Services will not
                    violate any applicable law or regulation.
                </p>
                <br />
                <p>
                    If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and
                    all current or future use of the Services (or any portion thereof).
                </p>

                <h2 className="text-2xl font-bold mt-8 mb-4">SOFTWARE</h2>

                <p>We may include software for use in connection with our Services. If such software is accompanied by an end user licence agreement ('EULA'), the terms of the EULA
                    will govern your use of the software. If such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and non-transferable
                    licence to use such software solely in connection with our services and in accordance with these Legal Terms. Any software and any related documentation is provided
                    'AS IS' without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular
                    purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except
                    in accordance with the EULA or these Legal Terms.
                </p>

                {/* <h2 className="text-2xl font-bold mt-8 mb-4">PURCHASES AND SUBSCRIPTIONS</h2>

                <p>
                    <strong>You will have the opportunity to purchase products and services from Sequence. If you purchase a subscription, it will automatically renew - and you will be
                        charged - until you cancel.</strong>
                </p>
                <br />
                <p>
                    Sequence may offer products and services for purchase through App Store, and Google Play, henceforth each referred to as <strong>'External Service'</strong>. Sequence may also offer
                    products and services for purchase via credit card or other payment processors on the Website or inside the App. If you purchase a subscription, it will automatically
                    renew until you cancel, in accordance with the terms disclosed to you at the time of purchase, as further described below. If you cancel your subscription, you will
                    continue to have access to your subscription benefits until the end of your subscription period, at which point it will expire.
                    <br />
                    <br />
                    Because our Services may be utilized without a subscription, canceling your subscription does not remove your profile from our Services. If you wish to fully terminate
                    your account, you must terminate your account as described in our Privacy Policy.
                    <br />
                    <br />
                    Sequence operates a global business, and our pricing varies based on a number of factors. To the extent permissible by law, we reserve the right, including without
                    prior notice, to limit the available quantity of or discontinue making available any product, feature, service or other offering; to impose conditions on the honoring
                    of any coupon, discount, offer or other promotion; to bar any user from making any transaction; and to refuse to provide any user with any product, service or other
                    offering.
                    <br />
                </p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="External Services">External Services</h3>
                <p>
                    <strong>External Service purchases, including subscriptions, may be processed through the External Service, in which case those purchases must be managed
                        through your External Service Account. Subscriptions automatically renew until you cancel.</strong>
                    <br />
                    <br />
                    When making a purchase on the Services, you may have the option to pay through an External Service, such as with your Apple ID or Google Play account
                    (your <strong>'External Service Account'</strong>), and your External Service Account will be charged for the purchase in accordance with the terms disclosed to you
                    at the time of purchase and the general terms applicable to your External Service Account. Some External Services may charge you sales tax, depending on where you
                    live, which may change from time to time.
                    <br />
                    <br />
                    If your External Service purchase includes an automatically renewing subscription, your External Service Account will continue to be periodically charged
                    for the subscription until you cancel. After your initial subscription commitment period, and again after any subsequent subscription period, the subscription
                    will automatically continue for the price and time period you agreed to when subscribing. If you received a discounted promotional offer, your price may go up
                    per the terms of that offer following the initial subscription period for any subsequent renewal period.
                    <br />
                    <br />
                    <strong>To cancel a subscription:</strong> If you do not want your subscription to renew automatically, or if you want to change or terminate your subscription,
                    you must log in to your External Service Account and follow instructions to manage or cancel your subscription, even if you have otherwise deleted your account
                    with us or if you have deleted the App from your device.
                    <br />
                    <br />
                    For example, if you subscribed using your Apple ID, cancellation is handled by Apple, not Sequence.
                    To cancel a purchase made with your Apple ID, go to Settings within the app , and follow the instructions to cancel. You can also request assistance at <a href="https://getsupport.apple.com/" class="text-blue-500 hover:text-blue-700 underline">
                        Apple Support
                    </a>.
                    <br />
                    <br />
                    Similarly, if you subscribed on Google Play, cancellation is handled by Google. To cancel a purchase made through Google Play, launch the Google Play app on
                    your mobile device and navigate to Settings. You can also request assistance at <a href="https://play.google.com/" class="text-blue-500 hover:text-blue-700 underline">
                        Google Play
                    </a>.
                    <br />
                    <br />
                    If you cancel a subscription, you may continue to use the canceled service until the end of your then-current subscription term. The subscription will not be renewed
                    when your then-current term expires.
                    <br />
                    <br />
                    If you initiate a chargeback or otherwise reverse a payment made with your External Service Account, Sequence may terminate your account immediately in its sole
                    discretion, on the basis that you have determined that you do not want a Sequence subscription. In the event that your chargeback or other payment reversal is
                    overturned, please contact us at info@sequence-studios.com. Sequence will retain all funds charged to your External Service Account until you cancel your subscription
                    through your External Service Account. Certain users may be entitled to request a refund. See Section below for more information.
                </p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Refunds">Refunds</h3>
                <p>
                    Generally, all purchases are final and nonrefundable, and there are no refunds or credits for partially used periods, except if the laws applicable in your
                    jurisdiction provide for refunds.
                    <br />
                    <br />
                    If any of the above apply to you and you subscribed using your Apple or Google Play account, your refund requests are handled by Apple and Google, not Sequence.
                    To request a refund, please contact your External Service directly; either by contacting Apple Support or Google Play Support, or by following their instructions
                    to request a refund.
                </p> */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="prohibited-activities">PROHIBITED ACTIVITIES</h2>

                <p>You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any
                    commercial endeavours except those that are specifically endorsed or approved by us.</p>
                <br />
                <p>As a user of the Services, you agree not to:</p>
                <br />

                <ul className="list-disc ml-5">
                    <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory
                        without written permission from us.</li>
                    {/* <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li> */}
                    <li>Trick, defraud, or mislead us, especially in any attempt to learn sensitive account information such as user passwords.</li>
                    <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any
                        Content or enforce limitations on the use of the Services and/or the Content contained therein.</li>
                    <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
                    {/* <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li> */}
                    <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                    <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                    <li>Engage in unauthorised framing of or linking to the Services.</li>
                    <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming
                        (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters,
                        or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
                    <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and
                        extraction tools.</li>
                    <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                    <li>Attempt to impersonate another user or person or use the username of another user.</li>
                    <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including
                        without limitation, clear graphics interchange formats ('gifs'), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or
                        'passive collection mechanisms' or 'pcms').</li>
                    <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</li>
                    <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
                    <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
                    <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                    <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the
                        Services.</li>
                    <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without
                        limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorised script or other software.</li>
                    <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
                    <li>Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of
                        sending unsolicited email, or creating user accounts by automated means or under false pretences.</li>
                    <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial
                        enterprise.</li>
                    <li>Use the Services to advertise or offer to sell goods and services.</li>
                    <li>Sell or otherwise transfer your profile.</li>
                    <li>Add copyrighted content that you do not own.</li>
                    <li>Use the Services' content for your own business.</li>
                </ul>

                {/* <h2 className="text-2xl font-bold mt-8 mb-4">USER GENERATED CONTRIBUTIONS</h2>

                <p>The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the
                    opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not
                    limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, 'Contributions').
                    Contributions may be viewable by other users of the Services and through third-party websites. As such, any Contributions you transmit may be treated as non-confidential
                    and non-proprietary. When you create or make available any Contributions, you thereby represent and warrant that:</p>
                <br />
                <ul className="list-disc ml-5">
                    <li>The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not
                        infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.</li>
                    <li>You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions to use and to authorise us, the Services, and
                        other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.</li>
                    <li>You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of
                        each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal
                        Terms.</li>
                    <li>Your Contributions are not false, inaccurate, or misleading.</li>
                    <li>Your Contributions are not unsolicited or unauthorised advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of
                        solicitation.</li>
                    <li>Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libellous, slanderous, or otherwise objectionable (as determined by us).</li>
                    <li>Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.</li>
                    <li>Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person and to promote violence against a specific person or
                        class of people.</li>
                    <li>Your Contributions do not violate any applicable law, regulation, or rule.</li>
                    <li>Your Contributions do not violate the privacy or publicity rights of any third party.</li>
                    <li>Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.</li>
                    <li>Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.</li>
                    <li>Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.</li>
                </ul>
                <br />
                <p>Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights
                    to use the Services.</p>
                <br />
                <p>If you choose to submit Contributions to the Services, you bear full responsibility for such Contributions and any resulting harm. This responsibility applies
                    regardless of whether the Contributions consists of text, graphics, audio files, or computer software. The Company does not claim ownership of the Contributions
                    uploaded by you on the Services. Therefore, the company cannot be held responsible, accountable, or liable for any claims, demands, losses, or damages arising
                    from the use of such Contributions.</p>
                <br />
                <p>The Company reserves the right to modify or remove any Contributions on the Services at its discretion and for any reason. This program is fully safeguarded under
                    copyright and trademark laws. Unauthorised duplication, exhibition, distribution, or any other use of the Services or any part thereof without prior written
                    consent is strictly prohibited by law. Violators will be prosecuted to the fullest extent of the law. The Content on the Services is primarily intended for the
                    personal, professional, or business use of a single user and for informational purposes only. In the event of a medical emergency, the Content should not be
                    relied upon for treatment; immediate contact with a doctor or a visit to a hospital is essential. The Content available on the Services is solely for training
                    and educational purposes and is not intended to serve as professional medical advice in any way. Dependence on the Content or any other information provided by
                    the Company, its employees, officers, contractors, agents, or others delivering Content on the Services, whether verbally, via email, or through in-person
                    conversation, is entirely at your own risk. The Services and the Content are provided on an "AS IS" basis.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">CONTRIBUTION LICENCE</h2>

                <p>By posting your Contributions to any part of the Services or making Contributions accessible to the Services by linking your account from the Services to any of
                    your social networking accounts, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited,
                    irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and licence to host, use, copy, reproduce, disclose, sell, resell,
                    publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute
                    such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of,
                    or incorporate into other works, such Contributions, and grant and authorise sublicences of the foregoing. The use and distribution may occur in any media formats
                    and through any media channels.</p>
                <br />
                <p>This licence will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name,
                    as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your
                    Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.</p>
                <br />
                <p>We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other
                    proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area
                    on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to
                    refrain from any legal action against us regarding your Contributions.</p>
                <br />
                <p>We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorise any Contributions to place
                    them in more appropriate locations on the Services; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no
                    obligation to monitor your Contributions.</p> */}

                <h2 className="text-2xl font-bold mt-8 mb-4">MOBILE APPLICATION LICENCE</h2>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Use Licence">Use Licence</h3>

                <p>If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to install and use the App on wireless electronic
                    devices owned or controlled by you, and to access and use the App on such devices strictly in accordance with the terms and conditions of this mobile application
                    licence contained in these Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt to derive the
                    source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement, translation, or derivative work from the App; (3) violate any
                    applicable laws, rules, or regulations in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice (including any notice
                    of copyright or trademark) posted by us or the licensors of the App; (5) use the App for any revenue-generating endeavour, commercial enterprise, or other purpose
                    for which it is not designed or intended; (6) make the App available over a network or other environment permitting access or use by multiple devices or users at the
                    same time; (7) use the App for creating a product, service, or software that is, directly or indirectly, competitive with or in any way a substitute for the App; (8)
                    use the App to send automated queries to any website or to send any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces or
                    our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications, accessories, or devices for use with the App.</p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Apple and Android Devices">Apple and Android Devices</h3>

                <p>The following terms apply when you use the App obtained from either the Apple Store or Google Play (each an 'App Distributor') to access the Services: (1) the licence
                    granted to you for our App is limited to a non-transferable licence to use the application on a device that utilises the Apple iOS or Android operating systems, as
                    applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service; (2) we are responsible for providing any maintenance
                    and support services with respect to the App as specified in the terms and conditions of this mobile application licence contained in these Legal Terms or as otherwise
                    required under applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance and support services with respect
                    to the App; (3) in the event of any failure of the App to conform to any applicable warranty, you may notify the applicable App Distributor, and the App Distributor,
                    in accordance with its terms and policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable law, the App
                    Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent and warrant that (i) you are not located in a country that is
                    subject to a US government embargo, or that has been designated by the US government as a 'terrorist supporting' country and (ii) you are not listed on any US government
                    list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using the App, e.g. if you have a VoIP application,
                    then you must not be in violation of their wireless data service agreement when using the App; and (6) you acknowledge and agree that the App Distributors are third-party
                    beneficiaries of the terms and conditions in this mobile application licence contained in these Legal Terms, and that each App Distributor will have the right (and will
                    be deemed to have accepted the right) to enforce the terms and conditions in this mobile application licence contained in these Legal Terms against you as a third-party
                    beneficiary thereof.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">SOCIAL MEDIA</h2>

                <p>As part of the functionality of the Services, you may link your account with online accounts you have with third-party service providers (each such account, a
                    'Third-Party Account') by either: (1) providing your Third-Party Account login information through the Services; or (2) allowing us to access your Third-Party Account,
                    as is permitted under the applicable terms and conditions that govern your use of each Third-Party Account. You represent and warrant that you are entitled to disclose
                    your Third-Party Account login information to us and/or grant us access to your Third-Party Account, without breach by you of any of the terms and conditions that govern
                    your use of the applicable Third-Party Account, and without obligating us to pay any fees or making us subject to any usage limitations imposed by the third-party
                    service provider of the Third-Party Account. By granting us access to any Third-Party Accounts, you understand that (1) we may access, make available, and store
                    (if applicable) any content that you have provided to and stored in your Third-Party Account (the 'Social Network Content') so that it is available on and through the
                    Services via your account, including without limitation any friend lists and (2) we may submit to and receive from your Third-Party Account additional information to
                    the extent you are notified when you link your account with the Third-Party Account. Depending on the Third-Party Accounts you choose and subject to the privacy settings
                    that you have set in such Third-Party Accounts, personally identifiable information that you post to your Third-Party Accounts may be available on and through your
                    account on the Services. Please note that if a Third-Party Account or associated service becomes unavailable or our access to such Third-Party Account is terminated by
                    the third-party service provider, then Social Network Content may no longer be available on and through the Services. You will have the ability to disable the connection
                    between your account on the Services and your Third-Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS ASSOCIATED WITH
                    YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make no effort to review any Social Network Content for any
                    purpose, including but not limited to, for accuracy, legality, or non-infringement, and we are not responsible for any Social Network Content. You acknowledge and agree
                    that we may access your email address book associated with a Third-Party Account and your contacts list stored on your mobile device or tablet computer solely for
                    purposes of identifying and informing you of those contacts who have also registered to use the Services. You can deactivate the connection between the Services and your
                    Third-Party Account by contacting us using the contact information below or through your account settings (if applicable). We will attempt to delete any information
                    stored on our servers that was obtained through such Third-Party Account, except the username and profile picture that become associated with your account.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">HEALTH & SAFETY</h2>

                <p>Breathwork exercises offered through the app are for informational and educational purposes only and are not a substitute for professional medical advice. By using these
                    features, you acknowledge that breathwork can be physically and mentally demanding and may not be suitable for everyone. If you have any pre-existing medical conditions,
                    including but not limited to respiratory or cardiovascular issues (such as, but not limited to, asthma, high blood pressure, or heart disease), or if you are pregnant,
                    you should consult a healthcare professional before engaging in any breathwork practice.Users are responsible for practicing in a safe, controlled environment. If you
                    experience dizziness, discomfort, or any adverse effects, discontinue immediately and seek medical attention. Sequence Studios assumes no liability for any injuries or
                    health issues arising from the use of these exercises.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">SERVICES MANAGEMENT</h2>

                <p>We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who,
                    in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole
                    discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions
                    or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content
                    that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property
                    and to facilitate the proper functioning of the Services.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">PRIVACY POLICY</h2>

                <p>We care about data privacy and security. Please review our Privacy Policy: https://sequence-studios.com/privacy-policy. By using the Services, you agree to be bound
                    by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in Belgium, Netherlands, United States and United
                    Kingdom. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that
                    differ from applicable laws in Belgium, Netherlands, United States and United Kingdom, then through your continued use of the Services, you are transferring your data
                    to Belgium, Netherlands, United States and United Kingdom, and you expressly consent to have your data transferred to and processed in Belgium, Netherlands, United
                    States and United Kingdom</p>


                <h2 className="text-2xl font-bold mt-5" id="copyright-infringements">COPYRIGHT INFRINGEMENTS</h2>

                <p>We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or
                    control, please immediately notify us using the contact information provided below (a 'Notification'). A copy of your Notification will be sent to the person who
                    posted or stored the material addressed in the Notification. Please be advised that pursuant to applicable law you may be held liable for damages if you make material
                    misrepresentations in a Notification. Thus, if you are not sure that material located on or linked to by the Services infringes your copyright, you should consider
                    first contacting an attorney.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">TERM AND TERMINATION</h2>

                <p>These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT
                    TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY
                    REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE
                    LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
                    WITHOUT WARNING, IN OUR SOLE DISCRETION.</p>

                <p>If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name,
                    or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the
                    right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">MODIFICATIONS AND INTERRUPTIONS</h2>

                <p>We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However,
                    we have no obligation to update any information on our Services. We will not be liable to you or any third party for any modification, price change, suspension,
                    or discontinuance of the Services.</p>

                <p>We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance
                    related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise
                    modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
                    caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed
                    to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">GOVERNING LAW</h2>

                <p>These Legal Terms are governed by and interpreted following the laws of the United Kingdom, and the use of the United Nations Convention of Contracts for the
                    International Sales of Goods is expressly excluded. If your habitual residence is in the EU, and you are a consumer, you additionally possess the protection
                    provided to you by obligatory provisions of the law in your country to residence. Sequence Studios Ltd and yourself both agree to submit to the non-exclusive
                    jurisdiction of the courts of London, which means that you may make a claim to defend your consumer protection rights in regards to these Legal Terms in the
                    United Kingdom, or in the EU country in which you reside.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">DISPUTE RESOLUTION</h2>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Informal Negotiations">Informal Negotiations</h3>

                <p>To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a 'Dispute' and collectively, the 'Disputes')
                    brought by either you or us (individually, a 'Party' and collectively, the 'Parties'), the Parties agree to first attempt to negotiate any Dispute (except those
                    Disputes expressly provided below) informally for at least sixty (60) days before initiating arbitration. Such informal negotiations commence upon written notice
                    from one Party to the other Party.</p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Binding Arbitration">Binding Arbitration</h3>

                <p>Any dispute arising from the relationships between the Parties to these Legal Terms shall be determined by one arbitrator who will be chosen in accordance with
                    the Arbitration and Internal Rules of the European Court of Arbitration being part of the European Centre of Arbitration having its seat in Strasbourg, and which
                    are in force at the time the application for arbitration is filed, and of which adoption of this clause constitutes acceptance. The seat of arbitration shall be
                    London, United Kingdom. The language of the proceedings shall be English. Applicable rules of substantive law shall be the law of the United Kingdom.</p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Restrictions">Restrictions</h3>

                <p>The Parties agree that any arbitration shall be limited to the Dispute between the Parties individually. To the full extent permitted by law, (a) no arbitration
                    shall be joined with any other proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action basis or to utilise class action
                    procedures; and (c) there is no right or authority for any Dispute to be brought in a purported representative capacity on behalf of the general public or any other
                    persons.</p>

                <h3 className="text-xl font-semibold mt-5 mb-2" id="Exceptions to Informal Negotiations and Arbitration">Exceptions to Informal Negotiations and Arbitration</h3>

                <p>The Parties agree that the following Disputes are not subject to the above provisions concerning informal negotiations binding arbitration: (a) any Disputes
                    seeking to enforce or protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any Dispute related to, or arising from,
                    allegations of theft, piracy, invasion of privacy, or unauthorised use; and (c) any claim for injunctive relief. If this provision is found to be illegal or
                    unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of this provision found to be illegal or unenforceable and
                    such Dispute shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to submit to the
                    personal jurisdiction of that court.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">CORRECTIONS</h2>

                <p>There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and
                    various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at
                    any time, without prior notice.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">DISCLAIMER</h2>

                <p>THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
                    BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
                    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
                    SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                    MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                    SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
                    SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                    OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
                    OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                    OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS
                    OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE
                    APPROPRIATE.</p>

                <h2 className="text-2xl font-bold mt-8 mb-4">LIMITATIONS OF LIABILITY</h2>

                <p>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                    SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
                    OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.
                    CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY
                    TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">INDEMNIFICATION</h2>

                <p>You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees,
                    from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out
                    of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal
                    Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other
                    user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence
                    and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use
                    reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">USER DATA</h2>

                <p>We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your
                    use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity
                    you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right
                    of action against us arising from any such loss or corruption of such data.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h2>

                <p>Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and
                    you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal
                    requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC
                    DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any
                    statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records,
                    or to payments or the granting of credits by any means other than electronic means.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">CALIFORNIA USERS AND RESIDENTS</h2>

                <p>If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the Division of Consumer Services of the California
                    Department of Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or
                    (916) 445-1254.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">MISCELLANEOUS </h2>

                <p>These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding
                    between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These
                    Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be
                    responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of
                    these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does
                    not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment or agency relationship created between
                    you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted
                    them. You hereby waive any and all defences you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute
                    these Legal Terms.</p>


                <h2 className="text-2xl font-bold mt-8 mb-4">CONTACT US</h2>

                <p>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at:</p>
                <br />
                <p><a href="mailto:info@sequence-studios.com" class="text-blue-500 hover:text-blue-700 underline">info@sequence-studios.com</a></p>
                <p>Sequence Studios Ltd.</p>
                <p>71 - 75 Shelton Street,</p>
                <p>Covent Garden,</p>
                <p>London WC2H 9JQ,</p>
                <p>UK</p>

                <p className='mt-4'><i>These Terms of Service were created using Termly's Generator.</i></p>
            </div>
            {
                showNavigationBars ? <Footer /> : null
            }
        </div>
    );
}

export default BreathLabTermsOfService;
