import React, { useState, useEffect } from "react";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already consented to cookies
    const consentGiven = localStorage.getItem("cookiesConsent");
    if (!consentGiven) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Save consent in localStorage and hide the banner
    localStorage.setItem("cookiesConsent", "true");
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="cookie-banner fixed bottom-0 w-full bg-gray-800 text-white p-4 flex justify-between items-center">
        <p className="text-sm">
          We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.{" "}
          <a href="/cookies-policy" className="underline">
            Learn more.
          </a>
        </p>
        <button
          onClick={handleAccept}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          Accept
        </button>
      </div>
    )
  );
};

export default CookieBanner;
