// SuccessPage.js
import React from 'react';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

const SuccessPage = () => (
    <div className="flex flex-col min-h-screen">
        <Navbar />
        <main className="flex-grow flex flex-col justify-center items-center text-center px-6">
            <h1 className="text-3xl font-bold mb-8">Message Sent Successfully!</h1>
            <p className="text-xl font-medium mt-5 mb-2">
                Thank you for contacting us. We'll get back to you shortly.
            </p>
        </main>
        <Footer />
    </div>
);

export default SuccessPage;
