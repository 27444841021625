import React from 'react';

const BreathLabHomeSection = ({
  title,
  subtitle,
  imageSrc,
  reverse = false,
  imageFlex = 'flex-[400]', // optional param with default
  buttonText = 'Get Breath Lab',
  buttonLink = '/breath-lab/download',
}) => {
  return (
    <a
      href={buttonLink}
      className="no-underline text-inherit"
    >
      <div
        className={`flex flex-col lg:flex-row mx-auto w-full lg:w-[70%] py-10 ${
          reverse ? 'lg:flex-row-reverse' : ''
        } items-center cursor-pointer`}
      >
        <div className={`${imageFlex} flex justify-center`}>
          <img src={imageSrc} alt="Descriptive" className="w-full h-auto max-w-[200px]" />
        </div>
        <div className="flex-[240] mt-4 lg:ml-10 flex flex-col justify-center">
          <h1 className="text-black font-inter font-medium text-lg mb-2">{title}</h1>
          <p className="text-gray-600 font-inter font-normal text-lg whitespace-pre-line mb-4">
            {Array.isArray(subtitle)
              ? subtitle.map((line, i) => (
                  <React.Fragment key={i}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              : subtitle}
          </p>
          <div className="mt-2">
            <div className="inline-block bg-black text-white px-4 py-2 rounded-md text-sm">
              {buttonText}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default BreathLabHomeSection;
